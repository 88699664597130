import React from 'react'

const Developer = () => {
  return (
    <div className='translate-y-50 mt-16 mb-20 p-4'>
    <h1>The Developer of this Site is Pranav Rai from India</h1>
    <h2>LinkedIn Url :<a style={{color:'blue'}}href='https://www.linkedin.com/in/pranavrai070/' target='blank'>LinkedIn</a></h2>

    
    </div>
  )
}

export default Developer
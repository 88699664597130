import parnerImg1 from '../assets/partner1.png';
import parnerImg2 from '../assets/partner2.png';
import parnerImg3 from '../assets/partner3.png';
import parnerImg4 from '../assets/partner4.png';
import parnerImg5 from '../assets/partner5.png';
import parnerImg6 from '../assets/partner6.png';

const partnerData=[
    {
        id:1,
        imageurl:parnerImg1
    },
    {
        id:2,
        imageurl:parnerImg2
    },
    {
        id:3,
        imageurl:parnerImg3
    },
    {
        id:4,
        imageurl:parnerImg4
    },
    {
        id:5,
        imageurl:parnerImg5
    },
    {
        id:6,
        imageurl:parnerImg6
    },
];

export default partnerData;